import React, { useEffect } from 'react';
import get from 'lodash/get';
import { graphql } from 'gatsby';
import Metas from 'components/metas';
import ContentRow from 'components/contentRow';
import SearchForm from 'components/searchForm';
import { useNavigationLinksContext } from 'contexts/navigationLinksContext';

const components = {
  ContentRow,
};

export default function Page({ data, location }) {
  const resetNavigationLinks = get(
    useNavigationLinksContext(),
    'resetNavigationLinks',
    () => {}
  );

  useEffect(() => {
    resetNavigationLinks();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const blocks = data.contentfulPage.contentBlocks
    ? data.contentfulPage.contentBlocks.map((blockData) => {
        const componentName = blockData.__typename
          .replace('Contentful', '')
          .trim()
          .replace(/\s+/g, '');
        let BlockComponent = components[componentName];

        return (
          <BlockComponent
            location={location}
            key={blockData.id}
            {...blockData}
          />
        );
      })
    : null;

  const page = data.contentfulPage;
  const metaTitle = page.metaTitle || page.title;

  return (
    <>
      <Metas
        title={`${metaTitle}`}
        description={page.metaDescription}
        shareImage={page.shareImage ? page.shareImage.file.url : null}
      />
      {page.title === 'Home' ? <SearchForm /> : null}
      {blocks}
    </>
  );
}

export const pageQuery = graphql`
  query PageQuery($id: String!) {
    contentfulPage(id: { eq: $id }) {
      ...PageFragment
    }
  }

  fragment PageFragment on ContentfulPage {
    title
    metaTitle
    metaDescription
    shareImage {
      file {
        url
      }
    }
    contentBlocks {
      ... on Node {
        __typename
        id
        ... on ContentfulContentRow {
          ...ContentRowFragment
        }
      }
    }
  }
`;
