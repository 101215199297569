import React from 'react';
import { Helmet } from 'react-helmet';
import favicon from 'images/favicon.ico';

export default function Metas({ title, description, shareImage }) {
  const metaDescription = description || 'Newsela support';
  const shareImageUrl = shareImage || '';
  return (
    <Helmet
      title={`${title} | Newsela`}
      meta={[
        { name: 'description', content: metaDescription },
        {
          name: 'viewport',
          content: 'width=device-width, initial-scale=1'
        },
        { name: 'theme-color', content: '#0557d5' },
        { property: 'og:type', content: 'website' },
        { property: 'og:site_name', content: 'Newsela' },
        { property: 'og:description', content: metaDescription },
        { property: 'og:image', content: shareImageUrl },
        { property: 'og:image:width', content: '1200' },
        { property: 'og:image:height', content: '630' },
        { name: 'twitter:card', content: 'summary_large_image' },
        { name: 'twitter:site', content: '@Newsela' },
        { name: 'twitter:image', content: shareImageUrl },
        {
          name: 'twitter:description',
          content: metaDescription
        },
        {
          name: 'google-site-verification',
          content: 'XPGlDp7Teb1GThIilMQ2mjVrlZlRaDhiWErquJigV3U'
        }
      ]}
    >
      <link rel='icon' href={favicon} />
    </Helmet>
  );
}
